.canvasContainer {
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    position: relative;
    pointer-events: none;
}
