.timer {
    color: var(--lightorange);
    font-size: 2rem;
    min-width: 56px;
    width: 56px;
    height: 56px;
    line-height: 54px;
    user-select: none;
    font-weight: bold;
    border: 2px solid var(--lightorange);
    border-radius: 100%;
    text-align: center;
    animation-name: bounce;
    animation-duration: 1s;
    animation-iteration-count: 90;
}

@media only screen and (max-width: 440px) {
    .timer {
        font-size: 24px;
        min-width: 46px;
        width: 46px;
        height: 46px;
        line-height: 44px;
    }
}

@media only screen and (max-width: 420px) {
    .timer {
        font-size: 20px;
        min-width: 40px;
        width: 40px;
        height: 40px;
        line-height: 38px;
    }
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    
    10% {
        transform: scale(1.02);
    }

    20% {
        transform: scale(1);
    }
}
