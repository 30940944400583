.navContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 16px;
    height: 50px;
    position: relative;
}

.controlsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 3;
}

.buttons {
    cursor: pointer;
    color: rgb(76, 76, 76);
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: rgb(219, 219, 219);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
}

.button:focus {
    outline: none;
}

.enabled {
    color: white;
    background-color: var(--orangered);
}

.enabled:hover {
    background-color: rgb(255, 132, 0);
}

.disabled {
    color: rgb(76, 76, 76);
    background-color: rgb(219, 219, 219);;
}

.disabled:hover {
    background-color: rgb(230, 230, 230);
}

.buttons svg {
    height: 20px;
    width: 30px;
}