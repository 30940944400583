.menuContainer {
    position: absolute;
    height: 100%;
    width: 300px;
    top: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
    background-color: #ffeb90;
}

.closed {
    right: -300px;
    box-shadow: none;
}

.open {
    right: 0;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
}

.menuListContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
}

.endGameBtn {
    width: 100%;
    font-size: 1.2rem;
    color: white;
    background-color: red;
    border: none;
    border-radius: 10px;
    padding: 10px 0;
    cursor: pointer;
    transition: 0.1s ease-out;
    font-weight: bold;
}

.endGameBtn:hover {
    background-color: rgb(255, 56, 56);
}

.endGameBtn:active {
    transform: translate(0px, 1px);
}

.overlay {
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    top: 0;
    right: 0;
    position: absolute;
    transition: 0.3s ease-in-out;
    opacity: 0.2;
    z-index: 1;
}

.overlayOpen {
    display: inherit;
}

.overlayClosed {
    display: none;
}
