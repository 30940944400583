*, :root{
  box-sizing: border-box;
  --offWhite: #fcfcfc;
  --orangered: #ff5100;
  --lightorange: #ff8400;
}

#root{
  width: 100%;
  height: 100%;
}

body {
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 235, 144);
  background-image: url('assets/svg/cloud.svg');
  background-repeat: repeat;
  background-size: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 1rem 0;
}

.crownBadge{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: lightgreen;
  color: white;
  position: absolute;
  top: -20px;
  left: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crownBadgeSmall {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: white;
  color: gold;
  position: absolute;
  top: -6px;
  left: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loader {
  animation: spin 1s infinite linear forwards;
  transform: rotate(0deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

