.wordToDrawContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    user-select: none;
}

.lettersContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
}

.letter {
    height: 2.7rem;
    font-size: 2.1rem;
    font-weight: bold;
    border-bottom: 2px solid;
    border-color: inherit;
    font-family: monospace;
    min-width: 1.25rem;
}

.spacer {
    min-width: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}

.roundText {
    font-weight: bold;
    font-size: 1rem;
    color: rgb(157, 157, 157);
    line-height: 0.8;
}

.roundNumber {
    font-weight: bold;
    font-size: 1rem;
}

@media only screen and (max-width: 440px) {
    .letter {
        height: 2.2rem;
        font-size: 1.7rem;
        min-width: 1rem;
    }

    .lettersContainer {
        gap: 0.2rem;
    }

    .spacer {
        min-width: 46px;
    }

    .roundText {
        font-size: 0.9rem;
    }

    .roundNumber {
        font-size: 0.9rem;
    }
}

.bounce {
    animation-name: bounce;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

.bounce:nth-child(2){
    animation-delay: 0.1s;
}
.bounce:nth-child(3){
    animation-delay: 0.2s;
}
.bounce:nth-child(4){
    animation-delay: 0.3s;
}
.bounce:nth-child(5){
    animation-delay: 0.4s;
}
.bounce:nth-child(6){
    animation-delay: 0.5s;
}
.bounce:nth-child(7){
    animation-delay: 0.7s;
}
.bounce:nth-child(8){
    animation-delay: 0.8s;
}
.bounce:nth-child(9){
    animation-delay: 0.9s;
}
.bounce:nth-child(10){
    animation-delay: 0.10s;
}
.bounce:nth-child(11){
    animation-delay: 0.11s;
}
.bounce:nth-child(12){
    animation-delay: 0.12s;
}
.bounce:nth-child(13){
    animation-delay: 0.13s;
}
.bounce:nth-child(14){
    animation-delay: 0.14s;
}
.bounce:nth-child(15){
    animation-delay: 0.15s;
}
.bounce:nth-child(16){
    animation-delay: 0.16s;
}
.bounce:nth-child(17){
    animation-delay: 0.17s;
}
.bounce:nth-child(18){
    animation-delay: 0.18s;
}
.bounce:nth-child(19){
    animation-delay: 0.19s;
}

@keyframes bounce {
    0%   { transform: scale(1,1) translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-7px);}
    50%  { transform: scale(1.05,.95) translateY(0); }
    58%  { transform: scale(1,1) translateY(-3px); }
    65%  { transform: scale(1,1) translateY(0);}
    100% { transform: scale(1,1) translateY(0);}
    }
