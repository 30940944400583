.backBtn {
    background-color: transparent;
    color: var(--lightorange);
    font-size: 1rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    transition: 0.2s ease-in-out;
    border-radius: 4px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

.backBtn:hover {
    transform: scale(1.1);
}