.wordSelectionBackground {
    min-height: 90vh;
    max-width: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-image: url('../../../assets/svg/paper.svg');
    background-repeat: no-repeat;
    background-position: 5px;
    flex-grow: 1;
    flex: 1;
    order: 2;
}

.menuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wordsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.wordBtn {
    background-color: var(--lightorange);
    color: white;
    font-size: 1.2rem;
    padding: 0.4rem 0.6rem;
    border-radius: 10px;
    border: none;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.08);
    transition: 0.1s ease-in-out;
    cursor: pointer;
}

.wordBtn:hover {
    transform: scale(1.05);
}

.wordBtn:active {
    transform: translate(2px, 2px);
}

@media only screen and (max-width: 760px) {
    .wordSelectionBackground {
        order: 1;
        width: 100%;
    }
}