.scorecardBackground {
    min-height: 90vh;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    background-image: url('../../../assets/svg/paper.svg');
    background-repeat: no-repeat;
    background-position: 5px;
    flex-grow: 1;
    flex: 1;
    order: 2;
    user-select: none;
    padding: 5%;
}

.scorecardContainer {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    color: var(--lightorange);
    margin-bottom: 1.5rem;
}

.titleSpans {
    line-height: 0.9;
}

.titleAndLetters {
    width: 90%;
}

.titleAndUL {
    width: 100%;
    min-width: 280px;
    max-width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.scorecardUL {
    list-style-type: none;
    width: 100%;
    font-weight: bold;
    font-size: 1.1rem;
}

.scoreLI {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.place {
    min-width: 60px;
    color: #8484fd;
}

.nickname {
    flex-grow: 1;
}

.pointDiff {
    opacity: 0.5;
    min-width: 60px;
    text-align: right;
}

.btnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 85%;
}

@media only screen and (max-width: 760px) {
    .scorecardBackground {
        order: 1;
        width: 100%;
        padding: 12% 5%;
    }
}

.liAnimation {
    animation-name: liAnimation;
    animation-duration: 0.9s;
    animation-timing-function: ease;
    transform: scale(0);
    animation-fill-mode: forwards;
}

.liAnimation:nth-child(1){
    animation-delay: 0.3s;
}
.liAnimation:nth-child(2){
    animation-delay: 0.4s;
}
.liAnimation:nth-child(3){
    animation-delay: 0.5s;
}
.liAnimation:nth-child(4){
    animation-delay: 0.6s;
}
.liAnimation:nth-child(5){
    animation-delay: 0.7s;
}
.liAnimation:nth-child(6){
    animation-delay: 0.8s;
}
.liAnimation:nth-child(7){
    animation-delay: 0.9s;
}
.liAnimation:nth-child(8){
    animation-delay: 0.10s;
}

@keyframes liAnimation {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }

}