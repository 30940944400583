.gameHomeContainer{
    width: 100%;
    height: 100%;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.interfaceContainer{
    background-image: url('../../assets/svg/paper.svg');
    background-repeat: no-repeat;
    background-position: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 660px;
    flex: 1;
    order: 2;
}

@media only screen and (max-width: 760px) {
    .interfaceContainer {
        order: 1;
        width: 100%;
        /* transform: translate(-20px, 0px); */
    }

    .gameHomeContainer {
        flex-direction: column;
    }
}