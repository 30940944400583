.waitingForPlayersBackground {
    min-height: 90vh;
    max-width: 800px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-image: url('../../assets/svg/paper.svg');
    background-repeat: no-repeat;
    background-position: 5px;
    flex-grow: 1;
    flex: 1;
    order: 2;
    position: relative;
    flex-direction: column;
    padding: 5%;
}

.waitingForPlayersContainer {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}


.roomCodeRow {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
}

.roomCodeContainer {
    font-weight: bold;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0.4rem;
    border-radius: 10px;
    background-color: #ffcd98;
}

.roomCodeText {
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
    transform: translate(0px, 5px);
    user-select: none;
}

.roomCode {
    font-size: 1.4rem;
    color: var(--lightorange);
    font-weight: bold;
    transform: translate(0px, -5px);
}

.spacer {
    min-height: 70px;
}

@media only screen and (max-width: 760px) {
    .waitingForPlayersBackground {
        order: 1;
        width: 100%;
        padding: 8% 5%;
    }
}


.mainContent{
    width: 84%;
    height: 86%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 400px;
    user-select: none;
}

.playerCount{
    color: orange;
    text-decoration: underline;
    font-size: 1.9rem;
}

.waitingForPlayersMessageContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}