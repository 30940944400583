.sidebarContainer{
    min-width: 120px;
    height: 100%;
    padding: 1rem 0.3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.left {
    order: 1;
}

.right {
    order: 3;
}

.playerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.5rem;
    user-select: none;
}

.playerAvatarAndName {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    user-select: none;
}

.playerAvatarAndBadges {
    position: relative;
}

.characterImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0;
}

.videoHTML {
    object-fit: cover;
    background-image: url('../../../assets/svg/nocamera.svg');
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(60, 65, 86);
}

.playerScoreBadge {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: -6px;
    right: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding-top: 2px;
  }

  .scoreAnimation {
      position: absolute;
      top: 50%;
      left: 70%;
      font-weight: bold;
      color: rgb(84, 214, 84);
      font-size: 1.1rem;
      z-index: 3;
      transform: translate(-50%, -50%) scale(0);
      animation: slideAndGrow 1.5s infinite ease-in-out forwards;
  }

  @keyframes slideAndGrow {
      from {
          transform: translate(-50%, -50%) scale(0);
      }

      to {
        transform: translate(-50%, -50%) scale(1.5);
        top: 20%;
        left: 140%;
    }
  }



@media only screen and (max-width: 760px) {
    .left {
        order: 2;
    }

    .right {
        order: 3;
    }

    .sidebarContainer {
        flex-direction: row;
    }
}