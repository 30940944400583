.welcomeBackground{
    max-width: 800px;
    position: relative;
    width: 100%;
    min-height: 90vh;
    padding: 5%;
    background-image: url("../../assets/svg/paper.svg");
    background-repeat: no-repeat;
}

.backBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    transform: translate(0px, 16px);
}

.welcomeContainer{
    width: 90%;
    height: 90%;
    padding: 1.6rem;
    user-select: none;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.6rem 0;
    margin: 0.6rem 0;
}

p{
    margin: 1rem 0;
}

.roomCodeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    margin-bottom: 2rem;
}

.roomCodeInput {
    font-size: 1.5rem;
    border: 2px solid var(--lightorange);
    border-radius: 6px;
    font-weight: bold;
    text-align: center;
    color: #474747;
}

.roomCodeInput:focus {
    outline: none;
}

.errorText {
    color: rgb(255, 104, 104);
    font-size: 1.05rem;
}
