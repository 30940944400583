.pageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    max-width: 1000px;
    gap: 1rem;
}

.menuContainer{
    margin-top: 1rem;
    background-color: rgba(71, 38, 6, 0.482);
    width: 94%;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 6px 6px 20px 1px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur(1px);
}

.menuContainer:first-child {
    min-height: 400px;
}

.wrappingContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap-reverse;
    user-select: none;
}

.nicknameInput{
    margin: 0;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.15);
    border: none;
    border-bottom: 2px solid white;
    font-size: 1.3rem;
    color: white;
    padding: 0.2rem 0.5rem;
    max-width: 300px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.nicknameInput:focus{
    outline: none;
}

.nicknameInput::placeholder{
    color: rgba(255, 255, 255, 0.724);
}

.characterIcons{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 0.3rem;
    cursor: pointer;
}

.selectedCharacterImg{
    border-radius: 100%;
    width: 140px;
    height: 140px;
    border: solid white 4px;
}

.videoHTML {
    object-fit: cover;
    background-image: url('../../assets/svg/nocamera.svg');
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(60, 65, 86);
}

.selectedCharacterText:empty::before{
    content: "\200b";
}

.goToLobbyMenu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.errorText {
    padding: 1rem 0;
    margin: 1rem 0;
    color: rgb(255, 211, 211);
    font-size: 1.05rem;
}