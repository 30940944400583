.guessContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.guess{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 0.9rem;
    /* animation-name: fadeout;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards; */
}


@keyframes fadeout {
    0% {opacity: 1;}
    100% {opacity: 0;} 
}

.shake{
    animation: shake 0.2s ease-in-out 0s forwards, fadeout 2s ease-in-out 1s forwards;
}

@keyframes shake {
    0% {
        transform: translate(-3px, 0);
    }
    25% {
        transform: translate(3px, 0);
    }
    50% {
        transform: translate(-3px, 0);
    }
    75% {
        transform: translate(3px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

.bounce{
    animation: bounce 0.2s ease-in-out 0s forwards, fadeout 2s ease-in-out 1s forwards;
}

@keyframes bounce {
    0% {
        transform: translate(0, 3px);
    }
    25% {
        transform: translate(0, -3px);
    }
    50% {
        transform: translate(0, 3px);
        transform: scale(1.1);
    }
    75% {
        transform: translate(0, -3px);
    }
    100% {
        transform: translate(0, 0);
        transform: scale(1);
    }
}