.artistInterfaceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 84%;
    gap: 0.6rem;
}

/* .wordToDrawContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
} */

