.toolbarContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.brushesContainer {
    display: flex;
    justify-content: center;
    gap: 0.2rem;
    align-items: center;
    user-select: none;
}

.brush {
    border-radius: 50%;
    background-color: rgb(49, 47, 47);
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.brush:hover{
    transform: scale(1.08);
}

.paletteContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    max-width: 154px;
    margin: 0.4rem 0;
    user-select: none;
}

.colorBlotch{
    width: 24px;
    height: 24px;
    border-radius: 4px;
    box-shadow: inset 0px 0px 4px 1px rgb(0, 0, 0, 0.15);
    cursor: pointer;
    position: relative;
    transition: 0.2s ease-in-out;
}

.colorBlotch:hover{
    transform: scale(1.08)
}

.colorInput {
    max-width: 24px;
    max-height: 24px;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
    position: relative;
    transition: 0.2s ease-in-out;
}

.colorInput::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: rgb(255,124,225);
    background: linear-gradient(317deg, rgba(255,124,225,1) 7%, rgba(255,70,70,1) 27%, rgba(255,197,71,1) 39%, rgba(255,251,71,1) 52%, rgba(131,255,80,1) 63%, rgba(92,235,255,1) 76%, rgba(101,101,255,1) 89%, rgba(255,124,225,1) 97%);
    border-radius: 4px;
    cursor: pointer;
}

.colorInput:hover{
    transform: scale(1.08)
}

.selected::after {
    content: '';
    width: 7px;
    height: 7px;
    background-color: white;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.controlsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff4e6;
    border-radius: 6px;
    padding: 0.3rem;
    user-select: none;
}

.controlBtn{
    background: none;
    border: none;
    color: var(--lightorange);
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.controlBtn:hover{
    transform: scale(1.08)
}

.guessInputContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    user-select: none;
}

.guessInput {
    border-radius: 40px;
    font-size: 1.1rem;
    padding: 0.1rem 1rem;
    max-width: 160px;
    border: 3px solid var(--lightorange);
}

.guessInput:focus {
    outline: none;
}